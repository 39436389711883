const Trim = (value) => value.replace(/\s/g, '');
export const isEmpty = (value: number | string | any[]): boolean => {
  if (value === null || value === undefined) return true;
  if (typeof value === 'undefined') {
    return true;
  }
  if (Array.isArray(value) && value.length <= 0) return true;
  const str = `${value}`;
  if (Trim(str) === '') return true;
  return false;
};
export const jsonStr2Obj = <T>(str: string): T => {
  let json = {};
  try {
    json = str ? JSON.parse(str) : {};
  } catch (error) {
    json = {};
  }
  return json as T;
};
/**
 * 删除图片或者其他地址中多余的斜杠
 * @param url 第一个地址
 * @param others 其他地址拼接，可以有无限个
 */
export const removeDuplicateSlashes = (url: string, ...others: string[]): string => {
  const resultUrl = others.reduce((preResult, item) => preResult + item, url);
  return resultUrl.replace(/([^:]\/)\/+/g, '$1');
};
export const rds = removeDuplicateSlashes;
export default {
  jsonStr2Obj,
  isEmpty,
  rds,
};
