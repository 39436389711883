import 'antd-mobile/es/global';
// 导入样式
import '@tea/component/tea.css';
// 导入style
import './styles/app.less';
import 'tdesign-react/es/style/index.css';

import './utils/storage';
import './styles/tailwind.css';
import './styles/mail.less';
import './styles/reset-onetrust.less';
import './styles/tdesign-reset.less';
import './styles/map.less';
// 导入依赖
import { RouteItem, app } from '@tea/app';
import { I18NInstance, init } from '@tea/app/i18n';

import 'regenerator-runtime/runtime.js';
import { lazy } from 'react';
import { lg } from './utils/language';
import { isCarbon } from './utils/oneTrust';
import { removeBasenameFromPathname } from './utils/Tools';
import { ConsoleRoute, FrontWeb, SINGLE_PAGE } from './routers';
const ILayout = lazy(() => import('./components/ui-components/Layout2/Layout'));

// 路由代码
type IRoute = RouteItem & {
  title?: string;
  titleEn?: string;
};
const carbon = isCarbon();
// eslint-disable-next-line @typescript-eslint/naming-convention
const routes: IRoute[] = [
  // 首页
  !carbon && { path: '', component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/home')) },
  FrontWeb,
  ConsoleRoute,
  ...SINGLE_PAGE,
  {
    path: '*',
    component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/home')),
  },
];

const lang: 'zh' | 'en' = lg.getLang();
const initCarbonLink = () => {
  replaceUrl();
  document.documentElement.lang = lang;
  const carbon = isCarbon();
  const baseName = lang === 'zh' && !carbon ? '/zh' : '';
  app.routes(
    routes.filter((v) => v),
    ILayout as any,
    baseName,
  );

  // 初始化国际化词条配置
  import('../i18n/translation/en').then((en) => {
    init({ en: en.translation }, () => {
      I18NInstance.changeLanguage(lang);
    });
  });
};
// 立即执行
initCarbonLink();

// 路由判断
function replaceUrl() {
  // 动态添加路由
  const { pathname, search } = window.location;
  if (pathname.startsWith('/zh/zh/')) {
    const pathname = removeBasenameFromPathname();
    location.href = `${window.location.origin}${pathname}${search}`;
  }
  const carbon = isCarbon();
  if (pathname.startsWith('/zh') && carbon) {
    const pathname = removeBasenameFromPathname();
    location.href = `${window.location.origin}${pathname}${search}`;
  }
  if (!pathname.startsWith('/zh') && lg.isChinese && !carbon) {
    // 生成新的URL
    const newPathname = `/zh${window.location.pathname === '/' ? '' : window.location.pathname}`;
    // 仅修改浏览器地址栏URL，不重新刷新页面
    window.history.replaceState(null, '', `${window.location.origin}${newPathname}${window.location.search}`);
  }
}
