/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable operator-linebreak */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/naming-convention */
import JSEncrypt from 'jsencrypt';
import { parseBigInt } from 'jsencrypt/lib/lib/jsbn/jsbn';
import qs from 'qs';
import { t } from '@tea/app/i18n';
import { lg } from './language';
import { Bubble, message } from '@tencent/tea-component';
import { initOneTrust } from './oneTrust';
import React from 'react';
import { jsonStr2Obj } from './common/StringUtils';
const { CAPTCHA_CONFIG } = gconfig;
const { PUBLIC_KEY } = gconfig;
const LoginUrl = gconfig.LOGIN_URL;
/**
 * 处理需加密信息
 * @param pwd 必须 需要加密的内容
 * @returns string 字符串
 */
export const encrypt = (pwd): string => {
  if (!pwd) {
    return undefined;
  }
  const encryptor = new JSEncrypt({});
  encryptor.setPublicKey(PUBLIC_KEY);
  const rsaPassWord = String(encryptor.encrypt(pwd));
  return rsaPassWord;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function pkcs1unpad2(d, _n) {
  const b = d.toByteArray();
  let i = 0;
  while (i < b.length && b[i] === 0) {
    ++i;
  }
  // if (b.length - i !== n - 1 || b[i] !== 2) {
  //   return null
  // }
  ++i;
  while (b[i] !== 0) {
    if (++i >= b.length) {
      return null;
    }
  }
  let ret = '';
  while (++i < b.length) {
    const c = b[i] & 255;
    if (c < 128) {
      // utf-8 decode
      ret += String.fromCharCode(c);
    } else if (c > 191 && c < 224) {
      ret += String.fromCharCode(((c & 31) << 6) | (b[i + 1] & 63));
      ++i;
    } else {
      ret += String.fromCharCode(((c & 15) << 12) | ((b[i + 1] & 63) << 6) | (b[i + 2] & 63));
      i += 2;
    }
  }
  return ret;
}
export const decryptUserInfo = (scretStr: string): string => {
  if (!scretStr) {
    return undefined;
  }
  const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzJjSiTVlXFe41T2kJ4mf
3CtlSCF+MSD+H/KBDlaKxVTsNQSpilYKZ1QSvgINRfbKLcC5zKZ77IHr8JH4s4O7
0WsGJF7WgTMt5aSLEgjxnyBfGIFPL/xswXnMbZXP8uOjqjyhuifQHASzHgOLwGy+
eLLfRydmS8uWZkFtYyT+mqz8TFGcd3GwH/8r27IT/pdz1D40zwt82YceD1jAwWu6
pDN9hrgOIs8NfnbDQ8xvKydfsv1+6Sx9DfkNhCRUT9jMqPsJ8wcECqVHGKulZJ3k
82wXfX/PBVduHlGQs83MMhiSTAzzABZYg7vXMFIlunr85mlQjzaf1sVf2V132DK5
0QIDAQAB
-----END PUBLIC KEY-----`;
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  // 不支持公钥解密
  // 自定义解析方法支持公钥解析
  const rsaKey = encrypt.getKey();
  rsaKey.decrypt = function (ctext) {
    const c = parseBigInt(ctext, 16);
    const m = this.doPublic(c);
    if (m === null) {
      return null;
    }
    return pkcs1unpad2(m, (this.n.bitLength() + 7) >> 3);
  };
  return String(encrypt.decrypt(scretStr));
};

const CAPTCHA_URL = 'https://turing.captcha.qcloud.com/TCaptcha.js';
/**
 * 防水码方法
 * @param account 用户名
 * @param platform 平台 tes-web | op
 */
const captchaIns = () => {
  let isLoad = false;
  const setCaptcha = (resolve) => {
    if (CAPTCHA_CONFIG.enable) {
      const captcha1 = new (window as any).TencentCaptcha(
        CAPTCHA_CONFIG.APPID,
        async (res) => {
          isLoad = false;
          if (res.ret === 0) {
            if (res.ticket.startsWith('trerror_')) {
              message.warning({ content: t('验证码加载失败') });
              resolve(false);
              return;
            }
            resolve(res);
          } else {
            resolve(false);
          }
        },
        {
          userLanguage: lg.getLang(),
        },
      );
      captcha1.show();
    } else {
      resolve(false);
    }
  };
  return () =>
    new Promise((resolve) => {
      if (window?.TencentCaptcha) {
        return setCaptcha(resolve);
      }
      if (!isLoad) {
        isLoad = true;
        const hideHandle = message.loading({
          content: '',
        });
        const script = document.createElement('script');
        script.src = `${CAPTCHA_URL}`;
        script.onload = () => {
          hideHandle.hide();
          setCaptcha(resolve);
        };
        script.onerror = () => {
          isLoad = false;
          resolve(false);
        };
        document.head.appendChild(script);
      }
    });
};
export const captcha: () => Promise<any> = captchaIns();

// const OneTrustConfig = {
//   pre: {
//     script1: {
//       type: 'text/javascript',
//       src: 'https://cdn-ukwest.onetrust.com/consent/5c6efc75-7033-4dee-bdff-5591f8348cef-test/OtAutoBlock.js',
//     },
//     script2: {
//       type: 'text/javascript',
//       src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
//       'data-document-language': 'true',
//       charset: 'UTF-8',
//       'data-domain-script': '5c6efc75-7033-4dee-bdff-5591f8348cef-test',
//     },
//   },
//   prod: {
//     script1: {
//       type: 'text/javascript',
//       src: 'https://cdn-ukwest.onetrust.com/consent/6490fd16-409e-4db1-83f6-2398102bd949/OtAutoBlock.js',
//     },
//     script2: {
//       type: 'text/javascript',
//       src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
//       'data-document-language': 'true',
//       charset: 'UTF-8',
//       'data-domain-script': '6490fd16-409e-4db1-83f6-2398102bd949',
//     },
//   },
// };

// 爬虫js
// function checkUserAgent() {
//   const { userAgent } = navigator;
//   const pattern =
//     /prerender|googlebot|bingbot|yandex|baiduspider|twitterbot|facebookexternalhit|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest\/0\.|pinterestbot|slackbot|vkShare|W3C_Validator|whatsapp/i;
//   return !pattern.test(userAgent);
// }

// export const initOneTrust = (): Promise<any> => {
//   // 在谷歌等爬虫下不进入页面
//   const isPassAgent = checkUserAgent();
//   if (!isPassAgent || isCarbon()) {
//     return Promise.resolve('no pass agent');
//   }
//   if (!['pre', 'prod'].find((v) => v === process.env.DEPLOY_ENV)) {
//     return Promise.resolve('no pre or prod env');
//   }
//   return new Promise((resolve: (res: any) => void) => {
//     if (!window.OneTrust) {
//       try {
//         let count = 0;
//         const config = OneTrustConfig[process.env.DEPLOY_ENV];
//         const scripts = Object.keys(config);
//         scripts.forEach((v) => {
//           const script = document.createElement('script');
//           const keys = Object.keys(config[v]);
//           keys.forEach((k) => {
//             script.setAttribute(k, config[v][k]);
//           });
//           script.onload = () => {
//             count++;
//             if (count === scripts.length) {
//               resolve('success');
//             }
//           };
//           document.head.appendChild(script);
//         });
//       } catch (error) {
//         resolve('error');
//       }
//     }
//   });
// };

interface IMessage {
  status: 'error' | 'success' | 'validating';
  message: any;
}
/** 根据message信息获取状态 */
export const getStatus = (info: any): IMessage => {
  if (!info) {
    return { status: undefined, message: null }; // 初始状态
  }
  return info?.message ? { status: 'error', message: info.message } : { status: 'success', message: undefined };
};

/** 正则表达式 */
const regexGroup = {
  httpUrl: /^(http|https):\/\/.*\..*$/, // 地址栏 "http://" 或者 "https://" 开头并且包含至少一个点号（.）
  require: /^\s*$/, // 非空
  username: /^[a-zA-Z0-9]{4,20}$/,
  // eslint-disable-next-line no-useless-escape
  email: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]+$/, // 邮箱地址
  china_mobile: /^1[3-9]\d{9}$/, // 手机号
  mobile: /^\+\d{1,10}-\d{1,20}$/, // 手机号
  bank: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/, // 银行卡
  tel: /\d{3}-\d{8}|\d{4}-\d{7}/, // 电话
  qq: /^[1-9][0-9]{4,10}$/, // QQ
  msgCaptcha: /^[0-9]{6}$/, // 验证码
  // 强密码
  // eslint-disable-next-line max-len
  sPassword:
    /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()\-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()\-+=]+$)(?![0-9\\W_!@#$%^&*`~()\-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()\-+=]{8,20}$/,
  // sPassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
  wPasseord: /^[a-zA-Z]\w{5,17}$/, //  弱密码
  character: /^[\u4e00-\u9fa5a-zA-Z0-9]+$/, // 匹配中文，英文字母和数字
  letterNum: /^[_a-zA-Z0-9]+$/, // 匹配英文字母和数字_
  numLimit: /^([0-9]{1,4}|10000)$/, // 0 到 10000 的正整数
  mobileAndTel: /(^1[3-9]\d{9}$)|(\d{3}-\d{8}|\d{4}-\d{7})/,
  floatNumber: /^[0-9]+(\.[0-9]{1,})?$/,
  eNotation: /^[+-]?[\d]+([\\.][\d]+)?([Ee][+-]?[\d]+)+$/,
  eNotationLt99: /([Ee][+-]?[\d]{1,2})+$/,
  percent: /^\d+\.{0,1}\d{0,}%$/,
  numberLimit: /(^[0-9]{1,10}$)|(^[0-9]{1,10}[.]{1}[0-9]{1,2}$)/,
  negative: /^[-|0-9][0-9]{1,}$/,
};

export { regexGroup };

/**
 * 解析url后的值
 * @param search   非必须 需要解析的字符串
 * @returns object 对象
 */
export const qsParse = (search?: any) => {
  if (!search || typeof search !== 'string') {
    search = window?.location?.search;
  }

  return qs.parse(search, { ignoreQueryPrefix: true });
};

export class Base64Safe {
  public static encode(e) {
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let t = '';
    let n;
    let r;
    let i;
    let s;
    let o;
    let u;
    let a;
    let f = 0;
    e = this.utf8_encode(e);
    while (f < e.length) {
      n = e.charCodeAt(f++);
      r = e.charCodeAt(f++);
      i = e.charCodeAt(f++);
      s = n >> 2;
      o = ((n & 3) << 4) | (r >> 4);
      u = ((r & 15) << 2) | (i >> 6);
      a = i & 63;
      if (isNaN(r)) {
        u = 64;
        a = 64;
      } else if (isNaN(i)) {
        a = 64;
      }
      t = t + keyStr.charAt(s) + keyStr.charAt(o) + keyStr.charAt(u) + keyStr.charAt(a);
    }
    return t?.replace(/\+/g, '-')?.replace(/\//g, '_');
  }

  public static utf8_encode = function (e: string) {
    e = e.replace(/rn/g, 'n');
    let t = '';
    for (let n = 0; n < e.length; n++) {
      const r = e.charCodeAt(n);
      if (r < 128) {
        t += String.fromCharCode(r);
      } else if (r > 127 && r < 2048) {
        t += String.fromCharCode((r >> 6) | 192);
        t += String.fromCharCode((r & 63) | 128);
      } else {
        t += String.fromCharCode((r >> 12) | 224);
        t += String.fromCharCode(((r >> 6) & 63) | 128);
        t += String.fromCharCode((r & 63) | 128);
      }
    }
    return t;
  };
}
interface WaterImgParam {
  text?: string;
  font?: string;
  fontsize?: number;
  fill?: string;
  dissolve?: number;
  gravity?: string;
  dx?: number;
  dy?: number;
  batch?: number;
  degree?: number;
}
/** 盲水印 */
export const waterImgUrlHandle = (url: string, param?: WaterImgParam) => {
  const initParam = {
    text: t('碳LIVE认证专用'),
    font: 'tahoma.ttf',
    fontsize: 13,
    fill: '#3D3D3D', // 字体颜色
    dissolve: 90, // 文字透明度
    gravity: 'center', // 水印位置
    dx: 0, // 边距
    dy: 0,
    batch: 1, // 开启水印平铺功能
    degree: 45, // 当batch为1时生效，水印旋转角度
    shadow: 0, // 文字阴影 0 - 100
  };
  const waterParam = { ...initParam, ...param };
  waterParam.text = Base64Safe.encode(waterParam.text);
  waterParam.font = Base64Safe.encode(waterParam.font);
  waterParam.fill = Base64Safe.encode(waterParam.fill);
  const handleUrl = `${url}${url.indexOf('?') > 1 ? '&' : '?'}watermark/2/text/${waterParam.text}/font/${
    waterParam.font
  }/fontsize/${waterParam.fontsize}/fill/${waterParam.fill}/dissolve/${waterParam.dissolve}/gravity/${
    waterParam.gravity
  }/batch/${waterParam.batch}/degree/${waterParam.degree}/shadow/${waterParam.shadow}`;
  return handleUrl;
};

/** uuid */
export const uuid = function () {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
};

export const iswechart = navigator.userAgent.toLowerCase().includes('micromessenger');

export const showAvatar = (cdn, origin) => {
  if (origin.startsWith('http')) {
    return origin;
  }
  return `${cdn}${origin}`;
};

/** 过滤非法链接，防止xss */
export const legalUrl = (url: string) => {
  if (url.startsWith('javascript:')) {
    return '';
  }
  return url;
};

// 并非所有的页面去登录都要记录回到上一页，以下页面的登录无需记录回调地址
const defaultPath = ['/', '/login', '/signup', '/findpwd'];
/**
 * 登录前先退出idass
 *
 */
export const login = () => {
  const pathname = removeBasenameFromPathname();
  if (!defaultPath.find((v) => v === pathname)) {
    // 当前路由不在名单中时，记录重定向地址
    sessionStorage.setItem('redirect_url', `${pathname}${location.search}`);
  }
  let idassLogoutUrl = '';

  try {
    const url = new URL(LoginUrl);
    if (url.pathname === '/login') {
      location.href = LoginUrl;
      return;
    }
    idassLogoutUrl = `${url.origin}/logout?return_to=${encodeURIComponent(LoginUrl)}`;
  } catch (error) {
    idassLogoutUrl = LoginUrl;
  }
  location.href = idassLogoutUrl;
};

/** referer来源的解释 */
export const referrerList = [
  { regexp: 'baidu.com', text: t('百度') },
  { regexp: 'www.baidu.com', text: t('百度') },
  { regexp: 'google.com', text: 'Google' },
  { regexp: 'www.tencent.com', text: 'Tencent' },
  { regexp: 'tanlive.com', text: t('碳live') },
  { regexp: 'cn.bing.com', text: 'Bing' },
  { regexp: 'ucenter.nengapp.com', text: t('能见') },
  { regexp: 'carbon.tencent.com', text: t('碳中和实验室') },
  { regexp: 'carbonxprogram.com', text: t('碳寻计划') },
  { regexp: 'zhuanlan.zhihu.com', text: t('知乎专栏') },
  { regexp: 'km.woa.com', text: t('KM平台') },
];

export const getReferrerName = () => {
  const { referrer } = document;
  if (!referrer) {
    return '';
  }
  try {
    const url = new URL(referrer);
    const refText = referrerList.find((v) => v.regexp === url.host)?.text || referrer;
    return refText;
  } catch (error) {
    return '';
  }
};

/**
 * isLatinChar
 * 是否为拉丁字符
 * @param char
 */
const LATIN_RANGE = [
  [0x0000, 0x02af],
  [0x1d00, 0x1dbf],
  [0x1e00, 0x1eff],
  [0x2000, 0x206f],
  [0x2c60, 0x2c7f],
  [0x2e00, 0x2e7f],
  [0xa720, 0xa7ff],
  [0xab30, 0xab6f],
  [0xfb00, 0xfb4f],
  [0xff00, 0xffef],
  [0x10780, 0x107bf],
  [0x1df00, 0x1dfff],
];
export const isLatinChar = (char: string): boolean => {
  const code = char?.charCodeAt?.(0);
  // حديقة الحكمة
  return LATIN_RANGE?.find?.((i) => code >= i?.[0] && code <= i?.[1])?.length >= 1;
};

/**
 * getStringLength
 * 获取字符串长度,拉丁字符为1,其他为2
 * @param str
 */
export const getStringLength = (str?: string): number => {
  if (!str) {
    return 0;
  }
  //
  let len = 0;
  for (let i = 0; i < str?.length; i++) {
    if (isLatinChar(str?.[i])) {
      len += 1;
    } else {
      len += 2;
    }
  }
  //
  return len;
};

// truncateString
export const truncateString = (str2 = '', limit = 0): string => {
  if (typeof str2 !== 'string') {
    return '';
  }
  const str = str2?.trim?.();
  let len = 0;
  let truncatedStr = '';
  if (str.length < limit / 2) {
    return str;
  }

  for (let i = 0; i < str?.length; i++) {
    const charLength = isLatinChar(str?.[i]) ? 1 : 2;

    if (len + charLength > limit) {
      break;
    }

    len += charLength;
    truncatedStr += str?.[i];
  }

  return truncatedStr;
};
export function removeBasenameFromPathname(pathname = location.pathname, basename = '/zh') {
  const regex = new RegExp(`^${basename}`);
  return pathname.replace(regex, '');
}

// 将字符串中的所有"！[]"替换为"![]"
export const markDownContent = (content = '') => {
  if (!content) return '';
  // 将字符串中的所有"！"替换为"!"
  const replacedStr1 = content.replace(/！/g, '!');

  // 将字符串中的所有"（"替换为"("
  const replacedStr2 = replacedStr1.replace(/（/g, '(');

  // 将字符串中的所有"）"替换为")"
  const replacedStr3 = replacedStr2.replace(/）/g, ')');

  const replacedStr4 = replacedStr3.split('! [] (').join('![](');

  const replacedStr5 = replacedStr4.replace('https：', 'https:');

  return replacedStr5;
};

// 文件扩展名和他们MIME映射：doc、docx、xls、xlsx、pdf、ppt、pptx、png、jpg、jpeg、mp4、wmv、gif
export const MIMETYPEMAP = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  mp4: 'video/mp4',
  wmv: 'video/x-ms-wmv',
  gif: 'image/gif',
  webm: 'video/webm',
  webp: 'image/webp',
  txt: 'text/plain',
  // md: 'text/markdown',
};
// 获取指定文件路径的conntettype，返回header请求头
export const getContentHeader = (path: string, key = 'Content-Type', name = '') => {
  const ext = path.split('.').pop().toLowerCase();
  const mimeType = MIMETYPEMAP[ext];
  const header = {
    [key]: mimeType,
  };
  if (ext === 'pdf' && name) {
    header['Content-Disposition'] = `attachment;filename=${encodeURI(name)}`;
  }
  return header;
};

export const formatPdfCDNUrl = (url: string) => {
  if (url.endsWith('.pdf')) {
    return `/pdfview?pdfUrl=${url}`;
  }
  return url;
};

// 递归移除对象中每个字段的前后的空格,返回对象
export const removeSpaceObject = (obj2) => {
  const obj = (jsonStr2Obj(JSON.stringify(obj2)) as any) || {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].map((item) => {
        if (typeof item === 'object') {
          return removeSpaceObject(item);
        }
        if (typeof item === 'string') {
          return item.trim();
        }
        return item;
      });
    } else if (typeof obj[key] === 'object') {
      removeSpaceObject(obj[key]);
    }
  }
  return obj;
};

export { initOneTrust };

/**
 * selectOnSearchScrollTop
 */
export const selectOnSearchScrollTop = () => {
  const op = document?.querySelector?.('.tea-dropdown-box')?.children?.[1];
  op && (op.scrollTop = 0);
};

export const onPrerenderReady = () => {
  // window.prerenderReady = true;
};

/**
 * 显示团队名简称,（团队名全称）
 * @param short {String} 团队简称
 * @param full {String} 团队全称
 * @returns {String} 团队名
 */
export const getTeamNameText = (short: string, full: string) => {
  if (!short) return '';
  return `${short}${full ? `（${full}）` : ''}`;
};

/**
 * 显示团队名简称, 鼠标悬停时显示全称
 * @param short {String} 团队简称
 * @param full {String} 团队全称
 * @param level {String} 级别 可选
 * @param team_id {String} 团队id 可选
 * @param customRoute {String} 自定义路由 可选
 * @returns {String} 团队名
 */
export const getTeamNameTextHover = (
  short: string,
  full: string,
  level?: any,
  team_id?: string,
  customRoute?: string,
) => (
  <Bubble content={full}>
    {team_id ? (
      <a id="QT3Uxr" className="text-[#006cff]" href={getTeamPath(customRoute, team_id)} target="_blank" rel="noreferrer">
        {short || full}
        {level}
      </a>
    ) : (
      <>
        {short || full}
        {level}
      </>
    )}
  </Bubble>
);

/**
 * 使用自定义路由跳转资源详情，如果不存在自定义路由，则用detail跳转
 * @param customRoute 可能存在的自定义路由
 * @param resId 资源id
 * @returns path
 */
export const getResourcePath = (customRoute: string, resId: string) => {
  if (customRoute) return `/@${customRoute}`;
  return `/programs/detail/${resId}`;
};

/**
 * 使用自定义路由跳转团队详情，如果不存在自定义路由，则用detail跳转
 * @param customRoute 可能存在的自定义路由
 * @param resId 资源id
 * @returns path
 */
export const getTeamPath = (customRoute: string, resId: string) => {
  if (customRoute) return `/@${customRoute}`;
  return `/innovators/detail/${resId}`;
};

/**
 * 比较两个版本大小
 * @param version1 旧版本版本1
 * @param version2 新版本版本2
 * @returns {number}
 */
export const compareVersions = (version1, version2) => {
  const arr1 = version1.split('.');
  const arr2 = version2.split('.');
  const maxLength = Math.max(arr1.length, arr2.length);
  for (let i = 0; i < maxLength; i++) {
    // 如果某个数组的元素已经遍历完，则视为0进行比较
    const num1 = parseInt(arr1[i] || 0, 10);
    const num2 = parseInt(arr2[i] || 0, 10);

    // 如果发现不相等的数字，则直接返回比较结果
    if (num1 !== num2) {
      return num1 - num2;
    }
  }
  return 0;
};

/**
 * 判断两个对象是否相等
 * @param obj1
 * @param obj2
 * @returns
 */
export function deepEqual(obj1, obj2) {
  function isObject(obj) {
    return obj !== null && typeof obj === 'object';
  }
  // 如果两个对象是同一个引用，则它们相等
  if (obj1 === obj2) {
    return true;
  }

  // 如果其中一个不是对象，则它们不相等
  if (!isObject(obj1) || !isObject(obj2)) {
    return false;
  }

  // 比较两个对象的键的数量
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // 递归比较每个键的值
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
export function handScrollToElement(
  selector: string | HTMLElement,
  viewOption?: boolean | ScrollIntoViewOptions,
  offset?: number,
): void {
  let element: HTMLElement | null = null;

  if (typeof selector === 'string') {
    if (selector.startsWith('#')) {
      // 处理 ID 选择器
      element = document.getElementById(selector.slice(1));
    } else if (selector.startsWith('.')) {
      // 处理 class 选择器
      element = document.querySelector<HTMLElement>(selector);
    } else {
      // 处理其他选择器
      element = document.querySelector<HTMLElement>(selector);
    }
  } else if (selector instanceof HTMLElement) {
    // 处理 DOM 元素
    element = selector;
  }

  if (element) {
    element.scrollIntoView(viewOption ? viewOption : { behavior: 'smooth' });
  } else {
    console.warn('Element not found:', selector);
  }

  if (offset) {
    setTimeout(() => {
      window.scrollBy({
        top: -offset,
        left: 0,
      });
    }, 100);
  }
}

export const onFnCatch = (data?: string) => {
  let url = null;
  try {
    url = JSON.parse(data);
  } catch (error) {
    url = data;
  }
  return url;
};
