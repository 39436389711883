/* eslint-disable @typescript-eslint/prefer-for-of */
export const CookieUtil = {
  /**
   * 设置 Cookie
   * @param {string} name - Cookie 名称
   * @param {string} value - Cookie 值
   */
  setCookie(name, value, days?) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  },

  /**
   * 获取 Cookie
   * @param {string} name - Cookie 名称
   * @returns {string|null} - Cookie 值，如果不存在则返回 null
   */
  getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  /**
   * 删除 Cookie
   * @param {string} name - Cookie 名称
   * @param {string} [path] - Cookie 路径（可选）
   */
  deleteCookie(name, path = '/') {
    document.cookie = `${name}=; Max-Age=-99999999; path=${path}`;
  },
};
